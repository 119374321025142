<!--
If the button starts an async process, this component automatically
manages the loading state. Instead of setting @click on the button,
set :click on this component, and return a promise.

Example:

<btn-loading-promise :click="callback">
	<template #default="{click,loading}">
		<v-btn @click="click" :loading="loading">Click me</v-btn>
	</template>
</btn-loading-promise>

-->
<script>
export default {
	props: {
		click: Function,
	},
	data(){
		return {
			loading: false,
		}
	},
	methods: {
		async onClick(params){
			if (this.loading || !this.click) return;
			this.loading = true;
			try {
				this.$emit('ready', await this.click(params));
			}
			catch (error) {
				if (this.$listeners.error) {
					this.$emit('error', await error);
				}
				else {
					throw error;
				}
			}
			finally {
				this.loading = false;
			}
		},
	},
	/**
	 * Renderless component which only returns the scoped slot "default"
	 */
	render(){
		return this.$scopedSlots.default && this.$scopedSlots.default({
			click: this.onClick,
			loading: this.loading,
		});
	},
}
</script>