<template>
	<div>
		<v-toolbar dense flat rounded :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" class="mt-2">
			Уреди
		</v-toolbar>
		<v-simple-table dense>
			<thead>
			<tr>
				<th>Тип, Номер, Локация</th>
				<th>Изтича на</th>
				<th>Старо</th>
				<th>Памет</th>
				<th>Показания</th>
			</tr>
			</thead>
			<tbody>
			<tr
				v-for="(device, index) in devices"
				:key="index">
				<td>
					<div v-if="device">
						<v-icon color="green" v-if="device.model && device.model.is_radio==='1'"
										style="vertical-align: bottom;" title="Радио">mdi-wifi
						</v-icon>
						{{ device.device.device_N }}<br>
						<span class="grey--text">
										{{ DeviceTypeLabels.get(device.device.device_type_code) }}<br>
										{{ device.device.room }}
									</span>
					</div>
				</td>
				<td>
					<date-format
						class="ma-1"
						v-if="device"
						:date="device && device.device.reinstall_date"
						past-class="red white--text pa-1 rounded"
					/>
					<up-device-alert-wrap
						:activator-props="{
										class: 'ma-1',
									}"
						readonly
						v-if="device"
						:up-list="up_list"
						:device-id="parseInt(device.device.id)"
						@reload="reload"
					/>
				</td>
				<td>
					<div v-if="device?.device_data">
						{{ parseFloat(device.device_data.value_old).toFixed(device.decimals) }}
					</div>
				</td>
				<td>
					<v-text-field
						label="Памет"
						v-if="doesDeviceHasMem(device)"
						:rules="[rules.validateValue(device?.mem)]"
						v-model="otcheti[device.device.device_N].value_mem"
						:disabled="readonly"
						dense hide-details="auto" outlined style="width: 120px;"
						class="my-2"
						@focus="$event.target.select()"
						inputmode="decimal"
					/>
				</td>
				<td>
					<v-text-field
						label="Показание"
						v-if="device?.device?.device_N"
						:rules="[rules.validateValue(device)]"
						v-model="otcheti[device.device.device_N].value"
						:disabled="readonly"
						dense hide-details="auto" outlined style="width: 120px;"
						class="my-2"
						@focus="$event.target.select()"
						inputmode="decimal"
					/>
				</td>
			</tr>
			</tbody>
		</v-simple-table>
		<v-divider/>
	</div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import {
	DeviceTypeLabels,
} from "@/ittijs/Nomenclatures";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import UpDeviceAlertWrap from "@/ittijs/components/UvedomitelniPisma/UpDeviceAlertWrap.vue";

export default {
	components: {
		UpDeviceAlertWrap,
		DateFormat,
	},
	props: {
		devices: {},
		otcheti: {}, // for syncing
		up_list: {},
		readonly: Boolean,
		isMissingDeviceData: {}, // for syncing
	},
	data() {
		return {
			rules: {
				validateValue: (maxSettings) => {
					const minExpectedValueStr = maxSettings?.min_expected_value ?? 0;
					const maxExpectedValueStr = maxSettings?.max_expected_value ?? 0;
					return (valueStr) => {
						if(!valueStr){
							return true;
						}
						const value = parseFloat(valueStr);
						if(value < 0){
							return 'Отрицателна стойност';
						}
						const minExpectedValue = parseFloat(minExpectedValueStr);
						if(minExpectedValueStr && value < minExpectedValue){
							return 'Отрицателна стойност';
						}
						const maxExpectedValue = parseFloat(maxExpectedValueStr);
						if(maxExpectedValueStr && value > maxExpectedValue){
							return 'Високо потребление';
						}
						return true;
					}
				},
				// required: value => !!value || 'Required.',
				// min: v => v.length >= 8 || 'Min 8 characters',
				// emailMatch: () => (`The email and password you entered don't match`),
			},
		}
	},
	computed: {
		DeviceTypeLabels: () => DeviceTypeLabels,
		isMissingDataInner(){
			return this.devices?.some(item => this.isMissingDataDevice(item)) ?? false;
		},
	},
	watch: {
		"otcheti": {
			deep: true,
			handler() {
				this.$emit('update:otcheti', this.otcheti);
			}
		},
		isMissingDataInner: {
			immediate: true,
			handler(val){
				this.$emit('update:isMissingDeviceData', val);
			},
		},
	},
	methods: {
		doesDeviceHasMem(device){
			return device?.device?.device_N && device?.mem
		},
		isMissingDataDevice(device){
			const otchetValue = this.otcheti[device.device.device_N]?.value;
			if(!this.doesDeviceHasMem(device)){
				return !otchetValue;
			}
			return !otchetValue || !(this.otcheti[device.device.device_N]?.value_mem);
		},
		reload() {
			this.$emit('reload');
		},
	},
}
</script>