<template>
	<my-dialog v-if="authorized" ref="dialog" title="Ново Уведомително Писмо" max-width="1200" v-model="dialogOpen">
		<template #activator="{attr,on}">
			<v-btn :disabled="disabled" v-bind="attr" v-on="on" small :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'">Ново УП</v-btn>
		</template>

		<CreateUPForm
			ref="deviceList"
			:imot="imot"
			:signature="signature"
			:up-type="upType"
			hide-clear-signature
			:is-signed.sync="isSigned"
			v-if="dialogOpen"
			:device-count.sync="deviceCount"
		/>

		<template #actions>
			<v-btn v-if="signature" :disabled="!isSigned" @click="$refs.deviceList.clearSignature()">
				Нов подпис
			</v-btn>
			<v-spacer/>
			<v-btn text @click="doClose" color="grey" class="mr-2">Затвори</v-btn>
			<confirm-dialog ref="confirmDialog">
				<p class="body-1">Моля потвърдете създаването на УП за {{ deviceCountText }}.</p>
			</confirm-dialog>
			<snack-bar-msg ref="snackBarMsg" />
			<v-btn color="primary" @click="doAttemptSave">Създай УП</v-btn>
		</template>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import CreateUPForm from "@/ittijs/components/UvedomitelniPisma/CreateUPForm.vue";
import ConfirmDialog from "@/ittijs/ConfirmDialog.vue";
import SnackBarMsg from "@/ittijs/components/SnackBarMsg.vue";
import {RolesEnum} from "@/ittijs/Authorization";

export default {
	components: {SnackBarMsg, ConfirmDialog, CreateUPForm, MyDialog},
	props: {
		imot: [Number, String], // id
		signature: Boolean,
		upType: String,
		disabled: Boolean,
	},
	inject: [
		'authorization',
	],
	data(){
		return {
			dialogOpen: false,
			deviceCount: 0,
			isSigned: false,
		}
	},
	computed: {
		authorized(){
			return this.authorization.hasAnyRole([
				RolesEnum.UVEDOMITELNI_PISMA,
				RolesEnum.MONTAJNIK,
				RolesEnum.OTCHETNIK,
			]);
		},
		deviceCountText(){
			return this.deviceCount === 1 ? '1 уред' : `${this.deviceCount} уреда`;
		},
	},
	methods: {
		async doAttemptSave(){
			if (this.deviceCount===0) {
				this.$refs.snackBarMsg.open('Изберете поне 1 уред', 'error');
				return;
			}
			if (await this.$refs.confirmDialog.open()) {
				await this.doSave();
			}
		},
		async doSave(){
			await this.$refs.deviceList.save();
			this.$refs.dialog.doClose();
			this.$emit('reload');
		},
		doClose(){
			this.$refs.dialog.doClose();
		},
	},
}
</script>