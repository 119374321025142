<template>
	<v-card outlined class="mt-2" v-if="deviceCounts && deviceCounts.length">
		<v-card-title>Уреди в станцията</v-card-title>
		<v-card-text>
			<v-simple-table dense>
				<thead>
				<tr>
					<th class="text-left">Тип</th>
					<th class="text-right">Радио</th>
					<th class="text-right">Не-радио</th>
					<th class="text-right">ОБЩО</th>
					<th class="text-right">Изтекли</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(d,i) of deviceCounts" :key="i">
					<td class="text-left">{{ d.type}}</td>
					<td class="text-right">{{ d.radio}}</td>
					<td class="text-right">{{ d.nonradio}}</td>
					<td class="text-right font-weight-bold">{{ d.radio + d.nonradio}}</td>
					<td :class="{'text-right':true,'red--text':d.expired>0}">{{ d.expired}}</td>
				</tr>
				</tbody>
				<tfoot>
				<tr>
					<th class="text-left">ОБЩО</th>
					<td class="text-right">{{ totalDevicesRadio }}</td>
					<td class="text-right">{{ totalDevicesNonRadio }}</td>
					<td class="text-right font-weight-bold">{{ totalDevicesRadio + totalDevicesNonRadio }}</td>
					<td :class="{'text-right':true,'red--text':totalDevicesExpired>0}">{{ totalDevicesExpired }}</td>
				</tr>
				</tfoot>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	components: {
	},

	props: {
		deviceCounts: {},
	},

	data() {
		return {
		}
	},

	computed: {
		totalDevicesRadio() {
			return (this.deviceCounts || []).reduce((cnt, elem) => cnt + elem.radio, 0);
		},
		totalDevicesNonRadio() {
			return (this.deviceCounts || []).reduce((cnt, elem) => cnt + elem.nonradio, 0);
		},
		totalDevicesExpired() {
			return (this.deviceCounts || []).reduce((cnt, elem) => cnt + elem.expired, 0);
		},
	},

}
</script>