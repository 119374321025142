<template>
	<v-snackbar v-bind="$attrs" v-model="show" :timeout="timeout"
		:color="colors[type]">

		<div v-for="(line,i) in text" :key="i">{{ line }}</div>

		<template v-slot:action="{ attrs }">
			<v-btn color="white" text v-bind="attrs" @click="show=false">
				Затвори
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	inheritAttrs: false,

	data() {
		return {
			show: false,
			colors: {
				default: 'primary darken-2',
				success: 'success',
				error: 'error',
				warning: 'orange',
			},
			type: 'default',
			text: [],
			timeout: 2500
		}
	},
	methods: {
		open(text, type = 'default', timeout = 2500) {
			this.close();
			this.$nextTick(function () {
				this.type = type;
				this.text = Array.isArray(text) ? text : [text];
				this.timeout = timeout;
				this.show = true;
			});
		},
		close() {
			this.show = false;
		}
	}
}
</script>

<style scoped>

</style>