<template>
	<my-dialog v-bind="$attrs" ref="dialog" max-width="800" title="Констативен протокол">
		<template #activator="{attrs,on}">
			<v-btn :disabled="disabled" v-bind="attrs" v-on="on" small :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'" class="ml-2">
				Констативен протокол
			</v-btn>
		</template>
		<protocol-findings-form
			v-bind="$attrs"
			ref="form"
			hide-clear-signature
			:is-signed.sync="isSigned"

			:findings.sync="findings"
			@update:findings="$emit('update:findings', $event)"

			:signature-svg.sync="signatureSvg"
			@update:signatureSvg="$emit('update:signatureSvg', $event)"

			@submit="$emit('submit')"
		/>
		<template #actions>
			<v-btn :disabled="disabled || !isSigned" @click="$refs.form.clearSignature()">
				Нов подпис
			</v-btn>
			<v-spacer/>
			<btn-loading-promise :click="doSubmit">
				<template #default="{click,loading}">
					<v-btn :disabled="disabled" color="primary" @click="click" :loading="loading">Потвърди</v-btn>
				</template>
			</btn-loading-promise>
		</template>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import ProtocolFindingsForm from "@/views/otcheti/otchetnik_ui/ProtocolFindingsForm.vue";

export default {
	components : {ProtocolFindingsForm, BtnLoadingPromise, MyDialog},
	props:{
		disabled: {},
	},
	data() {
		return {
			isSigned: false,
			findings: {},
			signatureSvg: {},
		}
	},
	methods: {
		doSubmit() {
			this.$refs.form.submit();
		},
	},
}
</script>