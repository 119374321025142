<!--
Превключва между широко и тясно представяне на таблица от ключове и стойности
Слотове:
	th-outer
	th-outer.[key]
	th
	th.[key]
	td-outer
	td-outer.[key]
	td
	td.[key]
-->

<template>
	<v-simple-table v-bind="$attrs" style="overflow: hidden">

		<thead class="headings2" v-if="!compact">
		<tr>
			<template v-for="(item, index) in data">
				<slot v-if="$scopedSlots['th-outer'] && !$scopedSlots['th-outer.'+item[propKey]]" name="th-outer" v-bind="item"></slot>
				<slot v-else :name="'th-outer.'+item[propKey]" v-bind="item">
					<th :key="index">
						<slot v-if="$scopedSlots['th'] && !$scopedSlots['th.'+item[propKey]]" name="th" v-bind="item"></slot>
						<slot v-else :name="'th.'+item[propKey]" v-bind="item">{{ item[propLabel] }}</slot>
					</th>
				</slot>
			</template>
		</tr>
		</thead>

		<tbody v-if="!compact">
		<tr>
			<template v-for="(item, index) in data">
				<slot v-if="$scopedSlots['td-outer'] && !$scopedSlots['td-outer.'+item[propKey]]" name="td-outer" v-bind="item"></slot>
				<slot v-else :name="'td-outer.'+item[propKey]" v-bind="item">
					<td :key="index">
						<slot v-if="$scopedSlots['td'] && !$scopedSlots['td.'+item[propKey]]" name="td" v-bind="item"></slot>
						<slot v-else :name="'td.'+item[propKey]" v-bind="item">{{ item[propValue] }}</slot>
					</td>
				</slot>
			</template>
		</tr>
		</tbody>

		<tbody v-if="compact">
		<tr v-for="(item, index) in data" :key="index">
			<slot v-if="$scopedSlots['th-outer'] && !$scopedSlots['th-outer.'+item[propKey]]" name="th-outer" v-bind="item"></slot>
			<slot v-else :name="'th-outer.'+item[propKey]" v-bind="item">
				<th class="text-right">
					<slot v-if="$scopedSlots['th'] && !$scopedSlots['th.'+item[propKey]]" name="th" v-bind="item"></slot>
					<slot v-else :name="'th.'+item[propKey]" v-bind="item">{{ item[propLabel] }}</slot>
				</th>
			</slot>
			<slot v-if="$scopedSlots['td-outer'] && !$scopedSlots['td-outer.'+item[propKey]]" name="td-outer" v-bind="item"></slot>
			<slot v-else :name="'td-outer.'+item[propKey]" v-bind="item">
				<td>
					<slot v-if="$scopedSlots['td'] && !$scopedSlots['td.'+item[propKey]]" name="td" v-bind="item"></slot>
					<slot v-else :name="'td.'+item[propKey]" v-bind="item">{{ item[propValue] }}</slot>
				</td>
			</slot>
		</tr>
		</tbody>

	</v-simple-table>
</template>

<script>
import scoped from "vue/packages/compiler-sfc/src/stylePlugins/scoped";

export default {
	computed: {
		scoped() {
			return scoped
		}
	},
	props: {
		data: Array,
		propKey: {
			type: String,
			default: 'key',
		},
		propLabel: {
			type: String,
			default: 'label',
		},
		propValue: {
			type: String,
			default: 'val',
		},
		compact: Boolean,
	},
}
</script>