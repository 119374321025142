<template>
	<v-input v-bind="$attrs" :rules="rules">
		<vue-signature-pad
			style="-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;"
			ref="signature"
			:width="width" :height="height"
			:options="{
				backgroundColor: this.backgroundColor,
				onEnd: onSignatureEnd
			}"
		/>
	</v-input>
</template>

<script>
import {VueSignaturePad} from "vue-signature-pad";
import Validators from "@/ittijs/Validators";

export default {
	components: {VueSignaturePad},
	props: {
		value: {},
		required: Boolean,
		backgroundColor: {
			default: '#ebe6c0',
		},
		width: {},
		height: {},
		isSigned: Boolean, // sync
	},
	data(){
		return {
			innerValue: this.value,
		}
	},
	computed: {
		rules(){
			return [
				() => this.innerValue == '' ? Validators.messages.required() : true
			];
		},
	},
	watch: {
		value(val){
			this.innerValue = val;
		},
		innerValue(val){
			this.$emit('input', val);
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.signature.resizeCanvas();
			if (this.value != '') {
				this.$refs.signature.fromDataURL(this.value);
				this.$emit('update:isSigned', true);
			}
		});
	},
	methods: {
		onSignatureEnd(){
			this.innerValue = this.$refs.signature.saveSignature('image/svg+xml')?.data ?? '';
			this.$emit('update:isSigned', true);
		},
		clearSignature() {
			this.$refs.signature.clearSignature();
			this.innerValue = '';
			this.$emit('update:isSigned', false);
		},
	},
}
</script>