import {Theme} from "vuetify/types/services/theme";

export const enum StationStatusEnum {
    NOT_STARTED = '',
    STARTED = 'initiated',
    FINISHED = 'completed',
    ERROR = 'error',
}

export const enum ImotStatusEnum {
    NOT_STARTED = '',
    NOT_OTCHETEN = 'not_otcheten',
    EMPTY = 'empty',
    STARTED = 'initiated',
    FINISHED = 'completed',
}


/**
 * Simple static link, no parameters
 */
export function getImotColor(status: ImotStatusEnum, theme: Theme): string {
    if (status === ImotStatusEnum.FINISHED) {
        return theme.dark ? 'teal darken-3' : 'green lighten-2';
    }
    if (status === ImotStatusEnum.EMPTY) {
        return 'warning';
    }
    if (status === ImotStatusEnum.NOT_OTCHETEN) {
        return 'accent';
    }
    if (status === ImotStatusEnum.STARTED) {
        return theme.dark ? 'deep-orange darken-4' : 'orange lighten-4';
    }
    return theme.dark ? 'grey darken-3' : 'grey lighten-4';
}