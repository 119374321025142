<template>
	<v-container fluid>
		<data-loader
			ref="loader"
			:callback="getImotView"
			:data.sync="data"
		>
			<template #loading>
				<v-progress-linear indeterminate rounded />
			</template>
			<template #default>
				<div v-if="data">
					<v-sheet>
						<v-toolbar flat dense rounded :color="getCardColor(data.imot.status)" class="mb-2">
							<v-btn @click="doClose" icon>
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>

							<v-toolbar-title>
								{{ data.imot.client_name }}
							</v-toolbar-title>

							<v-spacer/>

							<v-btn
								v-if="!progress && !isPast && data.imot.can_start" color="success" class="ml-2" @click="doStartImot"
								:disabled="saveLoading">
								Започни
							</v-btn>
							<v-btn
								v-if="!progress && data.imot.status === ImotStatusEnum.STARTED"
								class="ml-2"
								@click="doSetImotStatus(ImotStatusEnum.NOT_STARTED)"
								:disabled="saveLoading">
								Незапочнат
							</v-btn>
							<v-btn
								v-if="!progress && data.imot.can_set_status && data.imot.status !== ImotStatusEnum.EMPTY"
								color="warning" class="ml-2"
								@click="doSetImotStatus(ImotStatusEnum.EMPTY)"
								:disabled="saveLoading">
								Необитаем
							</v-btn>
							<v-btn
								v-if="!progress && data.imot.can_set_status && data.imot.status !== ImotStatusEnum.NOT_OTCHETEN"
								color="accent" class="ml-2"
								@click="doSetImotStatus(ImotStatusEnum.NOT_OTCHETEN)"
								:disabled="saveLoading">
								Неотчетен
							</v-btn>
							<v-btn
								v-if="!progress && !isPast && data.imot.can_reopen" color="success" class="ml-2" @click="doReopenImot"
								:disabled="saveLoading">
								Редакция
							</v-btn>
							<v-btn
								v-if="!progress && data.imot.can_finish"
								@click="sendYearlyOthcet"
								color="success" class="ml-2"
								:disabled="saveLoading">
								Приключи имота
							</v-btn>
							<send-yearly-otchet
								v-if="!progress && !isPast && data.imot.can_finish"
								ref="sendYearlyOtchet"
								:imot-yearly-otchet-id="data.imot.imot_yearly_otchet_id"
								:has-client-email="data.imot.client_email && data.imot.client_email.length > 0"
								:finish-only="data.imot.devices.length === 0 && data.imot.protocol_document_url"
								@ready="reload"
								:disabled="saveLoading"
							/>
							<confirm-dialog ref="confirmCompleteOtchet"
								title="Потвърждение"
								label-confirm="Продължи"
								color-confirm="red"
								label-cancel="Върни се обратно"
								:max-width="600"
							>
								<v-alert text type="warning" icon="mdi-alert-outline">
									Внимание! Проблеми с приключване на имота:
									<ul>
										<li v-show="isMissingDeviceData">Не са въведени данните за всички устройства</li>
									</ul>
								</v-alert>
							</confirm-dialog>
						</v-toolbar>
						<template v-if="!data.imot.readonly">
							<v-alert v-if="saveLoading" dense text type="warning" class="mb-2">
								<template #prepend>
									<v-progress-circular class="v-alert__icon" indeterminate size="24" width="2" />
								</template>
								Записване...
							</v-alert>
							<v-alert v-else-if="saveError" dense text type="error" class="mb-2">
								{{ saveError }}
								<template #append>
									<v-btn small color="error" @click="saveDebounced">Опитай отново</v-btn>
								</template>
							</v-alert>
							<v-alert v-else dense text type="success" icon="mdi-checkbox-marked-circle-outline" class="mb-2">
								Данните са записани!
							</v-alert>
						</template>

						<v-banner>
							{{ data.imot.address }} - {{ data.imot.status_human }}
							<a
								v-if="data.imot.otchet_document_url" :href="data.imot.otchet_document_url"
								class="mx-2" target="_blank">
								Отчетен документ</a>
							<a
								v-if="data.imot.protocol_document_url" :href="data.imot.protocol_document_url"
								class="mx-2" target="_blank"
							>
								Констативен протокол</a>
						</v-banner>

						<key-val-table
							dense
							class="elevation-2"
							:compact="$vuetify.breakpoint.smAndDown"
							:data="[
										{key: 'imot_N',     label: 'Имот номер',         val: data.imot.imot_N},
										{key: 'client_N',   label: 'Абонат номер',       val: data.imot.client_N},
										{key: 'name',       label: 'Име на абоната',     val: data.imot.client_name},
										{key: 'phone',      label: 'Телефон 1',          val: data.imot.client_phone},
										{key: 'phone2',     label: 'Телефон 2',          val: data.imot.client_phone2},
										{key: 'email',      label: 'Имейл',              val: data.imot.client_email},
										{key: 'station_N',  label: 'Станция номер',      val: data.station.station_N},
									]"
						>
							<template #td.phone="{val}"><a v-if="val && val!==''" :href="'tel:'+val">{{ val }}</a></template>
							<template #td.phone2="{val}"><a v-if="val && val!==''" :href="'tel:'+val">{{ val }}</a></template>
							<template #td.email="{val}"><a v-if="val && val!==''" :href="'mailto:'+val">{{ val }}</a></template>
						</key-val-table>

						<v-container>
							<v-row dense>
								<v-col>
									<v-textarea rows="2" v-model="data.imot.otchet_notes" outlined dense hide-details label="Бележки"
															class="mt-2" :disabled="data.imot.readonly" :readonly="data.imot.readonly"/>
								</v-col>
							</v-row>
						</v-container>

						<ImotDeviceList
							ref="ImotDeviceList"
							class="my-2"
							:up_list="data.imot.up_list"
							:devices="data.imot.devices"
							:otcheti.sync="data.imot.otcheti"
							:readonly="data.imot.readonly"
							:is-missing-device-data.sync="isMissingDeviceData"
							v-model="servicesData"
							@reload="reload"
						/>

						<CreateUP
							signature
							up-type="godishen"
							:imot="data.imot.imot_id"
							@reload="reload"
							:disabled="data.imot.readonly"
						/>
						<protocol-findings-popup
							:disabled="data.imot.readonly"
							@submit="doCreateProtocolFindings"
							:findings.sync="findings"
							:signature-svg.sync="signatureSvg" />
					</v-sheet>
				</div>
			</template>
		</data-loader>
	</v-container>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import KeyValTable from "@/ittijs/components/KeyValTable.vue";
import {debounceAsync, phpDate} from "@/ittijs/utils";
import CreateUP from "@/ittijs/components/UvedomitelniPisma/CreateUP.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import SendYearlyOtchet from "@/views/otcheti/otchetnik_ui/SendYearlyOtchet.vue";
import ConfirmDialog from "@/ittijs/ConfirmDialog.vue";
import ImotDeviceList from "@/views/otcheti/otchetnik_ui/ImotDeviceList.vue";
import {getImotColor, ImotStatusEnum} from "@/views/otcheti/otchetnik_ui/Helpers";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import ProtocolFindingsPopup from "@/views/otcheti/otchetnik_ui/ProtocolFindingsPopup.vue";

/* eslint-disable @typescript-eslint/camelcase */

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {
		ProtocolFindingsPopup,
		ImotDeviceList,
		ConfirmDialog,
		SendYearlyOtchet,
		CreateUP,
		KeyValTable,
		DataLoader,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
		ThrowMeMixin
	],
	props: {
		utility: {},
		session: {},
		date: {},
		station: {},
		imot: {},
		progress: {},
	},
	data(){
		return {
			ImotStatusEnum,
			tabs: null,
			data: null,
			snackbar: false,
			servicesData: null,
			saveDebounced: debounceAsync(this.startSave, 500),
			saveLoading: false,
			saveError: null,
			isOtchetSent: false,
			isDogovorVodaCreated: false,
			isTalonPlombiCreated: false,
			isMissingDeviceData: false,
			findings: '',
			signatureSvg: '',
			today: phpDate('Y-m-d', new Date),
			setIntervalHandle: null,
		}
	},
	mounted() {
		this.setIntervalHandle = setInterval(() => {
			this.today = phpDate('Y-m-d', new Date);
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.setIntervalHandle);
		this.setIntervalHandle = null;
	},
	computed: {
		devicesOtcheti(){
			return this?.data?.imot?.otcheti ?? null;
		},
		hasWarnings(){
			return this.isMissingDeviceData;
		},
		notes(){
			return this.data?.imot?.otchet_notes;
		},
		isPast(){
			return this.today > this.date;
		},
	},
	watch: {
		devicesOtcheti: {
			deep: true,
			handler(val, old){
				if(!this.data?.imot){
					return;
				}
				if (old !== null && !this.data.imot.readonly) {
					this.saveDebounced();
				}
			},
		},
		notes: {
			handler(val, old) {
				if (!this.data?.imot) {
					return;
				}
				if (old !== null && !this.data.imot.readonly) {
					this.saveDebounced();
				}
			},
		},
	},
	methods: {
		sendYearlyOthcet(){
			if(!this.hasWarnings){
				this.$refs.sendYearlyOtchet.open();
				return;
			}
			this.$refs.confirmCompleteOtchet.open()
				.then(res => {
					if(!res){
						return;
					}
					this.$refs.sendYearlyOtchet.open();
				})
		},
		getImotView(){
			return model.getImotView(this.utility, this.session, this.date, this.station, this.imot)
				.catch(err => {
					this.throwMe(err);
				});
		},
		doClose(){
			this.$router.push({
				name: this.$route.name,
				params: {
					utility: this.utility,
					session: this.session,
					date: this.date,
					station: this.station,
					imot: undefined,
				},
			})
		},
		getCardColor(status){
			return getImotColor(status, this.$vuetify.theme);
		},
		doStartImot(){
			this.saveLoading = true;
			return model.startImot(
				this.utility, this.session, this.date, this.station, this.imot
			)
				.then(() => this.$refs.loader.loadDebounce())
				.then(() => this.saveLoading = false)
				.catch(err => {
					this.saveLoading = false
					this.throwMe(err);
				});
		},
		doCreateProtocolFindings(){
			this.saveLoading = true;
			return model.createProtocolFindings(
				this.utility, this.session, this.date, this.station, this.imot, this.findings, this.signatureSvg
			)
				.then(() => this.$refs.loader.loadDebounce())
				.then(() => this.saveLoading = false)
				.catch(err => {
					this.saveLoading = false
					this.throwMe(err);
				});
		},
		doSetImotStatus(status){
			this.saveLoading = true;
			return model.setImotStatus(
				this.utility, this.session, this.date, this.station, this.imot, status
			)
				.then(() => this.$refs.loader.loadDebounce())
				.then(() => this.saveLoading = false)
				.catch(err => {
					this.saveLoading = false
					this.throwMe(err);
				});
		},
		doReopenImot() {
			return model.reopenImot(this.data.imot.imot_yearly_otchet_id)
				.then(() => this.$refs.loader.loadDebounce())
				.catch(err => {
					this.throwMe(err);
				});
		},
		startSave(){
			this.saveError = null;
			this.saveLoading = true;
			return model.updateImot(this.data.imot.imot_yearly_otchet_id, this.devicesOtcheti, this.notes)
				.then(() => {
					this.saveLoading = false;
				})
				.catch(e => {
					this.saveError = e.errors?.form?.pop() ?? e;
					this.saveLoading = false;
				});
		},
		reload(){
			this.$refs.loader.loadDebounce();
		},
	},
}
</script>