<template>
	<v-chip v-if="icon && color" :color="color" label dark :title="hint" small>
		<v-icon small>{{ icon }}</v-icon>
	</v-chip>
</template>

<script>
import {DeviceType, DeviceTypeLabels} from "@/ittijs/Nomenclatures";

export default {
	props: {
		type: String,
	},
	computed: {
		hint(){
			return DeviceTypeLabels.get(this.type);
		},
		icon(){
			switch (this.type) {
				case DeviceType.RAZPREDELITEL:
				case DeviceType.TOPLOMER:
					return 'mdi-radiator';
				case DeviceType.VODOMER_HOT:
				case DeviceType.VODOMER_COLD:
					return 'mdi-water-pump';
				case DeviceType.STUDOMER:
					return 'mdi-snowflake';
				case DeviceType.SHTRANGLIRA:
					return 'mdi-heating-coil';
				case DeviceType.ETALON:
					return 'mdi-radiator';
				case DeviceType.ETALON_SLAVE:
				case DeviceType.RADIATOR_BEZ_URED:
					return 'mdi-window-close';
			}
			return null;
		},
		color(){
			switch (this.type) {
				case DeviceType.RAZPREDELITEL:
				case DeviceType.TOPLOMER:
					return 'orange';
				case DeviceType.VODOMER_HOT:
					return 'deep-orange';
				case DeviceType.VODOMER_COLD:
					return 'light-blue';
				case DeviceType.STUDOMER:
					return 'light-blue';
				case DeviceType.SHTRANGLIRA:
				case DeviceType.ETALON:
				case DeviceType.ETALON_SLAVE:
				case DeviceType.RADIATOR_BEZ_URED:
					return 'purple';
			}
			return 'grey';
		},
	},
}
</script>