<template>
	<div style="display: contents;">
		<data-loader :callback="getDates" :data.sync="dates">
			<template #loading>
				<v-progress-linear indeterminate rounded />
			</template>
			<template #default>
				<v-tabs v-if="dates?.length" v-model="dateIndex" show-arrows>
					<v-tab v-for="date in dates" :key="date" v-bind="tabProps(date)">{{ formatDate(date) }}</v-tab>
				</v-tabs>
				<v-alert v-else-if="dates" type="info" class="ma-3" text outlined>
					Няма станции в графика
				</v-alert>
			</template>
		</data-loader>

		<v-container fluid v-if="dates?.length && date">

			<data-loader :watch="date" :callback="getDailySchedule">
				<template #loading>
					<v-progress-linear indeterminate rounded />
				</template>
				<template #default="{data}">
					<div v-if="data">
						<v-toolbar flat dense rounded :color="getCardColor('')" class="mb-2">
							<v-toolbar-title>
								({{ data.finishedCnt }} / {{ data.totalCnt }}) {{ data.finishedCnt === 1 ? 'приключена' : 'приключени' }}
							</v-toolbar-title>
						</v-toolbar>
						<v-timeline style="max-width: 800px;" dense align-top>
							<v-timeline-item v-for="item in data.stations" :key="item.id" small>
								<v-card :color="getCardColor(item.status)" elevation="2" @click="doOpenStation(item.id)">
									<v-btn v-if="item.mapUrl" absolute top right @click.stop
													target="_blank" :href="item.mapUrl" class="ml-2" color="primary">
										<v-icon left>mdi-map-marker-radius</v-icon>
										Карта
									</v-btn>
									<v-card-title>{{ item.station_N }}: {{ item.address }} - {{ item.status_human }}</v-card-title>
									<v-card-subtitle>{{ item.hour }}</v-card-subtitle>
									<v-card-text>
										<div v-if="item.total_imots_cnt">
											({{ item.finished_imots_cnt }} / {{ item.total_imots_cnt }}) приключени имоти
											({{ item.not_otcheteni }} неотчетени)
										</div>
										<div v-if="item.total_imots_cnt">
											<component
												:is="item.date1 === date ? 'strong' : 'span'">
												Първа дата "{{ formatDate(item.date1) }}"
											</component>,
											<component
												:is="item.date2 === date ? 'strong' : 'span'">
												Втора дата "{{ formatDate(item.date2) }}"
											</component>
										</div>
										<div v-if="progress">
											Отчетници: {{ item.users.join(', ') }}
										</div>
										<device-in-station v-if="item.device_counts" :deviceCounts="item.device_counts" />
									</v-card-text>
								</v-card>
							</v-timeline-item>
						</v-timeline>
					</div>
				</template>
			</data-loader>
		</v-container>
	</div>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import {isoToDate, phpDate, stationMapUrl} from "@/ittijs/utils";
import {monthsShort, weekdaysShort} from "@/ittijs/Nomenclatures";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import ExpansionPanelShort from "@/ittijs/ExpansionPanelShort";
import DeviceInStation from "@/views/devices/DeviceInStation.vue";
import {StationStatusEnum} from "@/views/otcheti/otchetnik_ui/Helpers";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";

const model = ITTIModelProvider.getModel(Model);

export default {
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			date: 'innerDate',
		}),
		ExpansionPanelShort,
		ThrowMeMixin,
	],
	components: {DeviceInStation, DataLoader},
	props: {
		utility: {},
		session: {},
		date: {},
		progress: {},
	},
	mounted() {
		this.setIntervalHandle = setInterval(() => {
			this.today = phpDate('Y-m-d', new Date);
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.setIntervalHandle);
		this.setIntervalHandle = null;
	},
	data(){
		return {
			dates: [],
			dateIndex: null,
			innerDate: null,
			// today: '2024-05-22',
			today: phpDate('Y-m-d', new Date),
			setIntervalHandle: null,
		}
	},
	computed: {
	},
	watch: {
		dates(){
			this.autoSelectFirstDate();
		},
		dateIndex(val){
			if (this.dates?.length) {
				this.innerDate = this.dates && this.dates[val] || null;
			}
		},
		date(){
			if (this.dates?.length) {
				this.autoSelectFirstDate();
			}
		},
	},
	methods: {
		getDates(){
			return model.getDates(this.utility, this.session, this.progress)
				.catch(err => {
					this.throwMe(err);
				});
		},
		getDailySchedule(){
			return model.getDailySchedule(this.utility, this.session, this.date, this.progress)
				.then(data => {
					data.stations.forEach(station => {
						station.mapUrl = stationMapUrl(station, data.city, station.address);
					});
					return data;
				})
				.catch(err => {
					this.throwMe(err);
				});
		},
		autoSelectFirstDate(){
			if (this.utility && this.dates?.length) {
				// if there's a date prop, try to match it
				if (this.date) {
					for (const index in this.dates) {
						if (this.dates[index] === this.date) {
							this.dateIndex = parseInt(index);
							this.innerDate = this.dates[index];
							return;
						}
					}
				}
				// try to select today
				for (const index in this.dates) {
					if (this.dates[index] === this.today) {
						this.dateIndex = parseInt(index);
						this.innerDate = this.dates[index];
						return;
					}
				}
				// give up, first tab will be selected
				this.dateIndex = null;
				this.innerDate = null;
			}
		},
		formatDate(date){
			date = isoToDate(date);
			if (date) {
				return `${weekdaysShort[date.getDay()]}, ${date.getDate()} ${monthsShort[date.getMonth()]}`;
			}
			return null;
		},
		tabProps(date){
			return date === this.today ? {
				"class": this.$vuetify.theme.dark ? 'cyan darken-4' : 'cyan lighten-5',
			} : {
			};
		},
		getCardColor(status){
			if (status === StationStatusEnum.ERROR) {
				return this.$vuetify.theme.dark ? 'red darken-3' : 'red lighten-4';
			}
			if (status === StationStatusEnum.FINISHED) {
				return this.$vuetify.theme.dark ? 'teal darken-3' : 'green lighten-4';
			}
			if (status === StationStatusEnum.STARTED) {
				return this.$vuetify.theme.dark ? 'deep-orange darken-4' : 'orange lighten-4';
			}
			return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4';
		},
		doOpenStation(station){
			if(!station){
				return;
			}
			this.$router.push({
				name: this.$route.name,
				params: {
					utility: this.utility,
					session: this.session,
					date: this.date,
					station: station,
				},
			});
		},
	},
}
</script>