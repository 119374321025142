<template>
	<v-container fluid>
		<data-loader
			ref="loader"
			:callback="getStationView"
			:data.sync="data"
		>
			<template #loading>
				<v-progress-linear indeterminate rounded/>
			</template>
			<template #default="{data}">
				<v-sheet v-if="data && data.station">
					<v-toolbar flat dense rounded :color="getStationColor(data.station.status)" class="mb-2">
						<v-btn @click="doClose" icon>
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<v-toolbar-title>
							{{ data.station.station_N }}: {{ data.station.address }}
						</v-toolbar-title>
						<a
							v-if="data.station.protocol_document_url" :href="data.station.protocol_document_url"
							class="mx-2" target="_blank"
						>
							Констативен протокол</a>
						<v-spacer/>
						<v-btn target="_blank" :href="stationMapUrl(data.station,data.city,data.station.address)" class="ml-2">
							<v-icon left>mdi-map-marker-radius</v-icon>
							Карта
						</v-btn>
						<v-btn v-if="canViewFinishButton" color="success" class="ml-2"
										@click="doFinishStation">
							Приключи
						</v-btn>
						<v-btn
							v-if="!progress && data.station.can_reopen" color="success" class="ml-2" @click="doReopenStation">
							Редакция
						</v-btn>
						<confirm-dialog ref="confirmCompleteStationOtchet"
														title="Потвърждение"
														:label-confirm="notFinishImots ? '': 'Приключи станцията'"
														color-confirm="red"
														label-cancel="Върни се обратно"
														:max-width="600"
						>
							<v-alert text type="error" icon="mdi-alert-outline">
								Внимание! Следните имоти трябва да бъдат приключени:
								<ul>
									<li v-for="imot in notFinishImots" :key="imot.id">
										{{ imot.imot_N }}: {{ imot.address }}
									</li>
								</ul>
							</v-alert>
						</confirm-dialog>
						<finish-station-yearly-otchet
							v-if="canFinishStation"
							ref="finishStationYearlyOtchet"
							:station-yearly-otchet-id="data.station.yearly_otchet_id"
							:imot-list-with-empty-data="imotListWithEmptyData"
							@ready="reload" />
					</v-toolbar>

					<v-breadcrumbs :items="data.imots_by_status">
						<template v-slot:item="{ item }">
							<v-breadcrumbs-item
								disabled
							>
								{{ item.label}}: {{ item.cnt }}
							</v-breadcrumbs-item>
						</template>
					</v-breadcrumbs>

					<v-timeline style="max-width: 800px;" v-if="data.imot_list.length" dense align-top>
						<v-timeline-item v-for="item in data.imot_list" :key="item.id" small>
							<v-card :color="getCardColor(item.status)" elevation="2" @click="doOpenImot(item.id)">
								<v-card-title>{{ item.imot_N }}: {{ item.address }}</v-card-title>
								<v-card-subtitle>{{ item.status_human }}</v-card-subtitle>
								<v-card-text>
									{{ item.client_name }} - <a v-if="item.client_phone" :href="'tel:'+item.client_phone">{{ item.client_phone }}</a>
								</v-card-text>
							</v-card>
						</v-timeline-item>
					</v-timeline>
				</v-sheet>
			</template>
		</data-loader>
	</v-container>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import {stationMapUrl} from "@/ittijs/utils";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ConfirmDialog from "@/ittijs/ConfirmDialog.vue";
import {getImotColor, StationStatusEnum, ImotStatusEnum} from "@/views/otcheti/otchetnik_ui/Helpers";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import FinishStationYearlyOtchet from "@/views/otcheti/otchetnik_ui/FinishStationYearlyOtchet.vue";

/* eslint-disable @typescript-eslint/camelcase */

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {
		FinishStationYearlyOtchet,
		ConfirmDialog,
		DataLoader,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
		ThrowMeMixin,
	],
	props: {
		utility: {},
		session: {},
		date: {},
		station: {},
		progress: {},
	},
	data() {
		return {
			data: {},
		}
	},
	computed: {
		notFinishImots(){
			const notAcceptableStatuses = [ImotStatusEnum.NOT_STARTED, ImotStatusEnum.STARTED];
			return this.data.imot_list.filter(i=> notAcceptableStatuses.some(status => status === i.status));
		},
		canViewFinishButton(){
			return !this.progress && this.data.station && this.data.station.can_finish && this.data.station.yearly_otchet_id;
		},
		canFinishStation(){
			return this.canViewFinishButton && !this.notFinishImots.length;
		},
		imotListWithEmptyData(){
			const emptyDataStatuses = [ImotStatusEnum.EMPTY, ImotStatusEnum.NOT_OTCHETEN];
			return this.data.imot_list.filter(i => emptyDataStatuses.some(status => status === i.status));
		},
	},
	watch: {
	},
	methods: {
		getStationView() {
			return model
				.getStationView(this.utility, this.session, this.date, this.station)
				.catch(err => {
					this.throwMe(err);
				});
		},
		stationMapUrl,
		doClose() {
			this.$router.push({
				name: this.$route.name,
				params: {
					utility: this.utility,
					session: this.session,
					date: this.date,
					station: undefined,
				},
			})
		},
		getStationColor(status) {
			if (status === StationStatusEnum.FINISHED) {
				return this.$vuetify.theme.dark ? 'teal darken-3' : 'green lighten-4';
			}
			if (status === StationStatusEnum.STARTED) {
				return this.$vuetify.theme.dark ? 'deep-orange darken-4' : 'orange lighten-4';
			}
			return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4';
		},
		getCardColor(status) {
			return getImotColor(status, this.$vuetify.theme);
		},
		doOpenImot(imot) {
			this.$router.push({
				name: this.$route.name,
				params: {
					utility: this.utility,
					session: this.session,
					date: this.date,
					station: this.station,
					imot: imot,
				},
			});
		},
		doFinishStation() {
			if(this.progress){
				return;
			}
			if(this.notFinishImots.length){
				return this.$refs.confirmCompleteStationOtchet.open();
			}
			return this.$refs.finishStationYearlyOtchet
				.open();
		},
		doReopenStation() {
			return model.reopenStation(this.data.station.yearly_otchet_id)
				.then(() => {
					return this.$refs.loader.loadDebounce();
				})
				.catch(res => {
					this.throwMe(res);
				});
		},
		reload() {
			this.$refs.loader.loadDebounce();
		},
	},
}
</script>